import React, {useEffect, useState} from 'react'
import styles from './styles.module.scss'
import FileList from "../FileList"
import {PktAlert, PktIcon} from "@oslokommune/punkt-react"
import {submitError} from "../../errorReport"
import {SoknadDokumentasjon} from "../../api/prissoknader";
import {classes} from "../../utils/utils";

type FilePickerProps = React.HTMLAttributes<HTMLDivElement> & {
    onChangeFiles?: (files: Array<File>) => void,
    acceptExtensions?: Array<string>,
    maxFileSize?: number,
    maxNumFiles?: number,
}

const FilePicker = ({onChangeFiles, acceptExtensions, maxFileSize, maxNumFiles, ...restProps}: FilePickerProps) => {
    const [files, setFiles] = useState<Array<File>>([])
    const [errors, setErrors] = useState<Array<any>>([])

    function addFiles(filesToAdd: Array<File>) {
        const newFiles = [...files]
        const errors: Array<{ text: string, filename: string }> = []
        const invalidFileNameCharacters = /[<>:"/\\|?*\x00-\x1F]/

        function addError(errorDescription: string, filename: string) {
            console.error(errorDescription)
            errors.push({text: errorDescription, filename: filename})
        }

        for (const file of filesToAdd) {
            if (maxNumFiles && newFiles.length === maxNumFiles) {
                addError(`Du kan bare laste opp 5 filer av gangen.`, file.name)
                submitError(`Fileupload: Too many files: ${maxNumFiles}`)
                break
            }
            const extension = file.name.split('.').pop() ?? ""
            if (acceptExtensions && !acceptExtensions.some(e => e.toLowerCase() === extension.toLowerCase())) {
                addError(`Filtypen støttes ikke. Gyldige filtyper er ${acceptExtensions.join(", ")}.`, file.name)
                submitError(`Fileupload: File extension is not accepted: '${extension}'`)
                continue
            }
            if (maxFileSize && file.size > maxFileSize) {
                addError(`Filen er for stor. Maks filstørrelse er 10MB`, file.name)
                submitError(`Fileupload: File is too large: ${file.size}`)
                continue
            }
            if (file.name.length > 255) {
                addError(`Filnavnet er for langt. Maks lengde er 255 tegn.`, file.name)
                submitError(`Fileupload: Filename is too long: ${file.name.length}`)
                continue
            }
            if (invalidFileNameCharacters.test(file.name)) {
                addError(`Filnavnet kan ikke inneholde noen av disse tegnene: <>\":?|\\/*`, file.name)
                submitError(`Fileupload: Filename has illegal character '${file.name.match(invalidFileNameCharacters)}'`)
                continue
            }
            newFiles.push(file)
        }
        setErrors(errors)
        setFiles(newFiles)
    }

    function removeFile(file: File | SoknadDokumentasjon) {
        setFiles(files.filter(f => f !== file))
    }

    useEffect(() => {
        onChangeFiles?.(files)
    }, [files, onChangeFiles])

    function dragOverHandler(ev: React.DragEvent) {
        ev.preventDefault()
        ev.dataTransfer.dropEffect = 'copy'
        ev.currentTarget.classList.add(styles.hover)
    }

    function dragEndHandler(ev: React.DragEvent) {
        ev.currentTarget.classList.remove(styles.hover)
    }

    function dropHandler(ev: React.DragEvent<HTMLDivElement>) {
        ev.preventDefault();
        ev.currentTarget.classList.remove(styles.hover)
        if (ev.dataTransfer.items) {
            const files = Array.from(ev.dataTransfer.items)
                .flatMap(item => {
                    const file = item.getAsFile()
                    return file ? [file] : [] //filter out nulls in a way that typescript understands
                })
            addFiles(files)
        } else {
            addFiles(Array.from(ev.dataTransfer.files))
        }
    }


    function onFileInputChange(ev: React.ChangeEvent<HTMLInputElement>) {
        addFiles(Array.from(ev?.currentTarget?.files ?? []))
    }

    return (
        <div {...restProps}>
            <div className={styles.dropZone}
                 onDrop={dropHandler}
                 onDragLeave={dragEndHandler}
                 onDragEnter={dragOverHandler}
                 onDragOver={dragOverHandler}>
                <div>
                    <PktIcon name="attachment" className={styles.dropZoneIcon}/>
                </div>
                <div>
                    Dra en fil hit <br/>
                    eller <label className={classes(styles.addFilesInputLabel, "pkt-link-button")} htmlFor="filePickerFileInput"> klikk
                    her<input id="filePickerFileInput"
                       type="file"
                       accept={acceptExtensions?.map(ext => `.${ext}`)?.join(',')}
                       className={styles.addFilesHiddenInput}
                       multiple={maxNumFiles == null || maxNumFiles > 1}
                       onChange={onFileInputChange}/></label> for å velge
                    {acceptExtensions && <div className="pkt-txt-12-light mt-size-8">
                        Filtyper: {acceptExtensions.map(e => e.toUpperCase()).join(", ")}
                    </div>}

                </div>
                
            </div>

            <FileList
                style={{marginBottom: "20px"}}
                className="pkt-txt-16-medium"
                files={files}
                onRemoveFile={removeFile}/>
            {errors && errors.map(({text, filename}, index) => (
                <PktAlert style={{wordBreak: "break-word"}}
                          key={filename}
                          className="my-size-8"
                          closeAlert={true}
                          onClose={() => setErrors(errors.filter((_, i) => index !== i))}
                          skin="error"
                          title={`Kunne ikke legge til ${filename}`}>
                    {text}
                </PktAlert>))}
        </div>

    )
}

export default FilePicker