import {Prissoknad} from "../../api/prissoknader"
import React, {useState} from "react"
import styles from "./styles.module.scss"
import PrissoknadStatus from "../Prissoknad"
import {classes} from "../../utils/utils"
import {PktIcon, PktTag} from "@oslokommune/punkt-react"
import {IPktTag} from "@oslokommune/punkt-react/dist/components/tag/Tag"

const soknadStatusText = Object.freeze({
    VENTER_PÅ_SKATTEOPPGJØR: 'Mottatt',
    OVERSENDT_SAKSBEHANDLER: 'Under behandling',
    FERDIGBEHANDLET: 'Ferdig behandlet',
    SKAL_IKKE_BEHANDLES: 'Stoppet'
})

const soknadStatusColor: { [id: string]: IPktTag['skin']; } = Object.freeze({
    VENTER_PÅ_SKATTEOPPGJØR: 'blue',
    OVERSENDT_SAKSBEHANDLER: 'beige',
    FERDIGBEHANDLET: 'green',
    SKAL_IKKE_BEHANDLES: 'grey'
})

type PrissoknadListItemProps = {
    prissoknad: Prissoknad,
    expandedByDefault?: boolean
}

const PrissoknadListItem = ({prissoknad, expandedByDefault = false}: PrissoknadListItemProps) => {
    const isExpandable = prissoknad.søknadsstatus !== "SKAL_IKKE_BEHANDLES"
    const [expanded, setExpanded] = useState(expandedByDefault && isExpandable)
    const soknadDate = prissoknad.innsendtdato && new Date(prissoknad.innsendtdato).toLocaleString("no-NB", {dateStyle: "short"})


    return <div className={styles.soknadListRowContainer}>
        <div className={classes(styles.soknadListRow, styles.columnText)}
             tabIndex={0}
             key={prissoknad.brukerreferanse}
             role={isExpandable ? "button" : undefined}
             onClick={() => isExpandable && setExpanded(!expanded)}
             onKeyDown={(ev: React.KeyboardEvent) => {
                 if (ev.code === 'Enter' || ev.code === 'Space') {
                     setExpanded(!expanded)
                     ev.preventDefault()
                 }
             }}>
            <span className={classes(styles.flexColumn)}>{soknadDate}</span>
            <span className={classes(styles.flexColumn)}>Redusert barnehagepris</span>
            <span className={classes(styles.flexColumn)}>
                {prissoknad.søknadstype === "RESØKNAD" ? 'Oslo kommune' : prissoknad.hovedsøkerFulltNavn}
            </span>
            <span className={classes(styles.flexColumn, styles.includeOnLargeScreen)}>
                <PktTag skin={soknadStatusColor[prissoknad.søknadsstatus]}>
                    {soknadStatusText[prissoknad.søknadsstatus as keyof typeof soknadStatusText]}
                </PktTag>
            </span>

            <div className={styles.expandButtonColumn} style={{visibility: isExpandable ? 'visible' : 'hidden'}}>
                <PktIcon name={expanded ? 'chevron-thin-up' : 'chevron-thin-down'}/>
            </div>
        </div>
        <div>
            {expanded && <PrissoknadStatus prissoknad={prissoknad} className="px-size-16 pb-size-16 mt-size-16" style={{}}/>}
        </div>
    </div>
}

export default PrissoknadListItem
