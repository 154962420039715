import React from "react";
import ReactDOM from "react-dom/client";
import "./main.scss";
import Barnehagepris from "./pages/Barnehagepris";
import keycloak, { KeycloakProvider } from "./keycloak";
import LoadingSpinner from "./components/LoadingSpinner";
import {
  createBrowserRouter,
  Link,
  RouterProvider,
} from "react-router-dom";
import { submitError } from "./errorReport";
import Header from "./components/Header";
import {
  PktBackLink,
  PktFooterSimple,
  PktIcon,
} from "@oslokommune/punkt-react";
import Feedback from "./components/Feedback";

import AvsluttPrissoknad from "./pages/AvsluttPrissoknad/AvsluttPrissoknad";
import {postFeedback} from "./api/feedback";
import {Oversikt} from "./pages/Oversikt/Oversikt";
import {config} from "./environment";
import ErrorHandler from "./pages/error/ErrorHandler";
import {BackLinkTilOversikt} from "./components/BackLinkTilOversikt";

window.addEventListener("error", (errorEvent) => {
  console.error("window error event", errorEvent);
  submitError("Unhandled error", errorEvent);
});

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="pkt-grid pkt-grid--laptop pkt-grid--center px-size-8 px-size-16--phablet-up">
      {children}
    </div>
  );
};

const BackLinkTilPrisSøknader = () => {
  // TODO: Dette er en gjenskapning av PktBacklink men med react-routers link
  // Om Punkt får støtte for custom componenter inn i PktBacklink kan denne fjernes

  return (
    <nav
      className="pl-size-48--laptop-up pl-size-16 pt-size-8 pkt-back-link"
      aria-label="Gå tilbake et steg"
    >
      <Link to={"/barnehagepris"} className={"pkt-link pkt-link--icon-left"}>
        <PktIcon
          name="chevron-thin-left"
          className="pkt-back-link__icon pkt-link__icon"
        />
        <span className="pkt-back-link__text">
          Dine søknader om barnehagepris
        </span>
      </Link>
    </nav>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element:
      <>
        <Layout>
          <Oversikt />
        </Layout>
      </>
    ,
    errorElement: <ErrorHandler/>
  },
  {
    path: "/barnehagepris",
    element: (
      <>
        <BackLinkTilOversikt />
        <Layout>
          <Barnehagepris />
        </Layout>
      </>
    ),
  },
  {
    path: "/barnehagepris/avsluttsoknad",
    element: (
      <>
        <BackLinkTilPrisSøknader />
        <Layout>
          <AvsluttPrissoknad />
        </Layout>
      </>
    ),
  },
]);

const Footer = () => {

  return (
    <PktFooterSimple
      className={"mt-size-32"}
      personvernOgInfoLink={"https://www.oslo.kommune.no/barnehage/personvernerklaring-for-barnehage/"}
      links={[
        {
          text: 'Kontakt oss',
          href: "https://www.oslo.kommune.no/barnehage/kontakt-barnehageadministrasjonen/",
          openInNewTab: true,
        },
      ]}
    />
  )
};

const root = ReactDOM.createRoot(document.getElementById("root") as Element);
root.render(
  <React.StrictMode>
    <KeycloakProvider LoadingComponent={LoadingSpinner()}>
        <div style={{minHeight: "100vh", display: "grid", gridTemplateRows: "auto 1fr auto"}}>
          <Header />
          <div>
            <RouterProvider router={router} />
            <Feedback
              maxTextFeedbackLength={2000}
              onThumbsFeedback={(thumb) => {
                postFeedback(keycloak, {
                  system: "kuen",
                  location: window.location.href,
                  ref: "index",
                  rating: thumb,
                });
              }}
              onTextFeedback={async (text) => {
                if (text) {
                  await postFeedback(keycloak, {
                    system: "kuen",
                    location: window.location.href,
                    ref: "index",
                    comment: text,
                  });
                }
              }}
            />
          </div>
          <Footer />
        </div>
    </KeycloakProvider>
  </React.StrictMode>
);
