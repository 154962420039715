import React from "react"
import {Prissoknad, usePrissoknader} from "../../api/prissoknader"
import {ResoknadStatus, useResoknadStatus} from "../../api/resoknadstatus"
import LoadingSpinner from "../LoadingSpinner"
import styles from "./styles.module.scss"
import PrissoknadListItem from "./PrissoknadListItem"
import {classes} from "../../utils/utils"
import {AlertError} from "../Alert/alerts"
import {useKeycloak} from "../../keycloak"
import {EmptyState} from "../Alert/EmptyState";


type PrissoknadListProps = React.HTMLAttributes<HTMLDivElement> & {
  resoknadMedlemskap?: ResoknadStatus
}

const PrissoknadList = ({style, className = ''}: PrissoknadListProps) => {
  const {keycloak} = useKeycloak()

  const [resoknadMedlemskap, resoknadMedlemskapStatus] = useResoknadStatus(keycloak)
  const [prissoknader, prissoknaderStatus] = usePrissoknader(keycloak)

  const shouldShowSubtext = resoknadMedlemskapStatus === 'ready'
    && resoknadMedlemskap !== 'INGEN'
    && prissoknaderStatus === 'ready'
    && prissoknader?.length === 0

  return (
    <>
      <div className={`${styles.soknadListContainer} ${className}`} style={style}>
        <div className={classes(styles.soknadListHeader, styles.headerText)}>
          <span className={classes(styles.flexColumn)}>Dato</span>
          <span className={classes(styles.flexColumn)}>Søknadstype</span>
          <span className={classes(styles.flexColumn)}>Innsendt av</span>
          <span className={classes(styles.flexColumn, styles.includeOnLargeScreen)}>Status</span>
          <div className={styles.expandButtonColumn} style={{width: "16px"}}></div>
        </div>
        {shouldShowSubtext && (
          <p className={styles.emptyTableSubtext}>
              Oversikt over dine søknader vil dukke opp her.
          </p>
        )}
        {prissoknaderStatus === 'ready' && prissoknader?.length === 0 && resoknadMedlemskap === 'INGEN' && (
          <EmptyState/>
        )}

        {prissoknaderStatus === 'ready' && prissoknader?.map((prissoknad: Prissoknad, index: number) =>
          <PrissoknadListItem prissoknad={prissoknad}
                              key={prissoknad.brukerreferanse}
                              expandedByDefault={index === 0}/>)}
      </div>

      {prissoknaderStatus === 'pending' && <LoadingSpinner/>}

      {prissoknaderStatus === 'error' && <AlertError/>}
    </>
  )
}

export default PrissoknadList
