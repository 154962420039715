export const config = {
    keycloakClientId: import.meta.env.VITE_KEYCLOAK_CLIENT_ID,
    keycloakRealm: import.meta.env.VITE_KEYCLOAK_REALM,
    keycloakUrl: import.meta.env.VITE_KEYCLOAK_URL,
    apiUrl: import.meta.env.VITE_API_URL ?? "",
    link440Skjema: import.meta.env.VITE_OVERSIKT_LINK_440_SKJEMA,
    linkIstSaker: import.meta.env.VITE_OVERSIKT_LINK_IST_SAKER,
    linkIstPlasseringer: import.meta.env.VITE_OVERSIKT_LINK_IST_PLASSERINGER,
    linkBarnehageBaseUrl: import.meta.env.VITE_OVERSIKT_LINK_BARNEHAGE_BASE_URL,
}

Object.entries(config).forEach(([k, v]) => {
    if (v === undefined) {
        throw Error(`Missing env variable: ${k}`)
    }
})