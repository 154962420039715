import {useState} from "react"
import {usePrissoknader} from "../../api/prissoknader"
import {useResoknadStatus} from "../../api/resoknadstatus"
import {trekkHustandOgAlleAktivePrissoknader} from "../../api/trekkprissoknader"
import {useKeycloak} from "../../keycloak"

function useAvsluttPrissoknad() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<unknown>(null)
  const [isSuccess, setIsSuccess] = useState<boolean>()
  const {keycloak} = useKeycloak()
  const [, , , invalidateResoknadStatus] = useResoknadStatus(keycloak)
  const [, , , invalidateSoknader] = usePrissoknader(keycloak)

  const avsluttPrissoknader = async () => {
    setLoading(true)
    setError(null)

    try {
      await trekkHustandOgAlleAktivePrissoknader(keycloak)
      invalidateResoknadStatus()
      invalidateSoknader()
      setIsSuccess(true)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  return {loading, error, isSuccess, avsluttPrissoknader}
}

export default useAvsluttPrissoknad
