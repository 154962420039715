import { ReactNode } from 'react'

export function ErrorLayout({ children }: { children: ReactNode }) {
    return (
        <div className="pkt-cell pkt-cell--span12" style={{ textAlign: 'center', padding: '4rem 0 6rem 0' }}>
            <svg style={{ height: '200px' }} viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M19.6813 3.7989L15.5386 4.14268L11.396 4.48647L11.7389 8.6292L7.59623 8.97299L7.93763 13.1122L3.79975 13.4556L4.14265 17.5983L4.48554 21.7411L8.62819 21.3973L8.28569 17.2593L12.4247 16.9146L12.4232 16.9112L12.4279 16.9108L12.0854 12.7728L16.2233 12.4294L16.2244 12.4281L15.8815 8.28541L20.0242 7.94162L19.6813 3.7989Z"
                    fill="#FF8274"
                />
                <path
                    d="M26.3368 19.8796L24.775 23.2406L23.2133 26.6016L19.8519 25.0406L18.2902 28.4016L14.9313 26.8429L13.3713 30.2L10.01 28.639L6.64865 27.078L8.2104 23.717L11.5679 25.2762L13.1292 21.9185L13.1317 21.9209L13.1335 21.917L16.491 23.4762L18.0509 20.1191L18.0523 20.1186L21.4137 21.6796L22.9754 18.3186L26.3368 19.8796Z"
                    fill="#FF8274"
                />
            </svg>
            {children}
        </div>
    )
}