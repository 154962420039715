import Keycloak from "keycloak-js";
import {config} from "../environment";
import {useAsync} from "../utils/utils";

export type Placement = {
    avdelingsNavn: string
    barnehageNavn: string
    bydelNavn: string
    startDato: string
    telefonnummer: string
}

export type Child = {
    navn: string,
    barnehageplasser: Array<Placement>,
}

type ChildPlacementResult = {
    barn: Array<Child>
}

export type ApiErrorChildPlacement = {
    message: string,
    status: number
    type: BarnehagebarnErrorType,
}

export enum BarnehagebarnErrorType {
    BarnUkjentPerson = "BarnUkjentPerson",
    GuardianUkjentPerson = "GuardianUkjentPerson",
    InfoIkkeTilgjengelig = "InfoIkkeTilgjengelig",
    Teknisk = "Teknisk"
}

async function getBarnehagePlasseringer(keycloak: Keycloak): Promise<Array<Child>> {
    await keycloak.updateToken(30)
    return fetch(`${config.apiUrl}/api/barnehagebarn`, {
        headers: {Authorization: `Bearer ${keycloak.token}`},
    })
        .then(async res => {
            if (!res.ok) {
                let apiError = await res.json() as ApiErrorChildPlacement
                throw new PlasseringerError(apiError.type)
            }
            return res
        })
        .then(res => res.json() as Promise<ChildPlacementResult>)
        .then(json => json.barn)
}

export class PlasseringerError extends Error {
    errorType: BarnehagebarnErrorType

    constructor(errorType: BarnehagebarnErrorType) {
        super();
        this.errorType = errorType;
    }
}

export const useBarnehagePlasseringer = useAsync(getBarnehagePlasseringer)
