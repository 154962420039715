import {classes} from "../../utils/utils";
import styles from "./alerts.module.scss";
import {PktIcon} from "@oslokommune/punkt-react";
import {config} from "../../environment";
import React from "react";

export const EmptyState = () => {
    return (
        <section className={classes(styles.emptyStateMessage, "mt-size-60")}>
            <PktIcon name="alert-information"/>
            <h1 className="pkt-txt-18-medium">
                Finner ingen søknader om redusert barnehagepris
            </h1>

            <p>
                For å få redusert barnehagepris, må du sende inn en søknad. Du kan
                følge behandlingen av søknaden din her.
            </p>

            <a
                className={classes("mt-size-16", "pkt-btn", "pkt-btn--small")}
                href={config.link440Skjema}
            >
                Søk om redusert barnehagepris
            </a>
        </section>
    );
};
