import {RefObject, useEffect, useMemo, useState} from "react";
import { useKeycloak } from "../keycloak";

export function trimLines(strings: TemplateStringsArray, ...values: any) {
    return String.raw({raw: strings}, values)
        .trim()
        .split(/\n\s*\n/)
        .map(l=>l.trim().split('\n').map(l2=>l2.trim()).join(' '))
        .join('\n\n')
}

export function classes(...classes: Array<string | null | undefined | false>) {
    return classes.filter(Boolean).join(' ')
}

//TODO: use a context for this instead?
export function useUsername() {
    const {keycloak, initialized} = useKeycloak()
    return useMemo(
        () => {
            if (initialized && keycloak) {
                const {given_name, family_name} = keycloak.idTokenParsed ?? {}
                return `${given_name} ${family_name}`
            }
            return ""
        },
        [keycloak, initialized],
    )
}

export function useResizeObserver(ref: RefObject<HTMLElement>, callback: (entries: ResizeObserverEntry[], observer: ResizeObserver) => void) {
    useEffect(() => {
        if(!ref.current) return
        const observer = new ResizeObserver(callback)
        observer.observe(ref.current)
        return () => observer.disconnect()
    })
}

// Simple throttle/debounce function. Returns a function that invokes the original function
// at most once every `wait` ms. First invocation `wait` ms after first call.
function throttle(f: (...args: any[]) => any, wait: number) {
    let timeout: number
    let previous: number = 0
    return (...args: any[]) => {
        window.clearTimeout(timeout)
        const now = Date.now()
        const elapsed = now - previous
        if (!previous || elapsed > wait) {
            timeout = window.setTimeout(f, wait, ...args)
            previous = now
        } else {
            timeout = window.setTimeout(f, wait - elapsed, ...args)
        }
    }
}
export const saveToSessionStore = (key: string, value: string | object) => {
    sessionStorage.setItem(key, JSON.stringify(value))
}
export const saveToSessionStoreThrottled = throttle(saveToSessionStore, 3000)

export function getFromSessionStore(key: string) {
    const item = sessionStorage.getItem(key)
    return item ? JSON.parse(item) : {}
}

function memoize<T>(fn: (...args: any) => Promise<T>) {
    let promise: Promise<T> | undefined

    const memoizedFn = (...args: any) => {
        if (promise) {
            return promise
        } else {
            return promise = fn(...args)
        }
    }

    memoizedFn.invalidate = () => {
        promise = undefined
    }

    return memoizedFn
}

type UseAsyncState = 'pending' | 'ready' | 'error' | undefined
type UseAsyncResult<T> = T | undefined
type UseAsyncError = unknown | undefined

export function useAsync<T>(fn: (...args: any[]) => Promise<T>, useMemoization: boolean = true) {
    let promise: Promise<T> | undefined

    return function (...args: any[]): [UseAsyncResult<T>, UseAsyncState, UseAsyncError, () => void] {
        const [result, setResult] = useState<UseAsyncResult<T>>(undefined)
        const [error, setError] = useState<UseAsyncError>(undefined)
        const [state, setState] = useState<UseAsyncState>(undefined)
        useEffect(() => {
            if(state !== undefined) return
            setState('pending')
            if(!promise || !useMemoization) {
                promise = fn(...args)
            }
            promise.then((result) => {
                setResult(result)
                setState('ready')
            })
            .catch((err) => {
                setState('error')
                setError(err)
                throw err //nå veit vi strengt tatt ikke lenger om den faktisk blir handled, men kaster den likevel videre så den plukkes opp av konsollen og feilrapporteringa
            })
        }, [state])

        const reset = () => {
            promise = undefined
            setState(undefined)
            setError(undefined)
            setResult(undefined)
        }
        return [result, state, error, reset]
    }
}