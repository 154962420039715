import keycloak from "../keycloak";
import {config} from "../environment";
import {v4 as uuidv4} from "uuid";

function encodeTusMetadata(metadata: object) {
  return Object.entries(metadata)
  .map(([key, value]) => `${key} ${window.btoa(value)}`)
  .join(",")
}

export async function uploadFiles(files: Array<File>, brukerref: string, fnr: string, innsendingId: string = uuidv4()) {
  await keycloak.updateToken(30)
  const formData = new FormData()
  files.forEach(file => formData.append("files", file, file.name))
  const res = await fetch(`${config.apiUrl}/api/vedlegg`, {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      'Upload-Metadata': encodeTusMetadata({brukerref, fnr, innsendingId})
    }
  })
  if (!res.ok) {
    throw Error(`Unexpected response status: ${res.status}`)
  }
}