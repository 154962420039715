import React from 'react'
import LoadingSpinner from "../LoadingSpinner"
import BarnehageBarn from "./Barnehagebarn"
import {
  AlertError,
  AlertNoPlacement,
  AlertIkkeVisBarnehageplass,
} from "../Alert/alerts"
import {useKeycloak} from '../../keycloak'
import {BarnehagebarnErrorType, PlasseringerError, useBarnehagePlasseringer} from "../../api/plasseringer"

function getErrorComponent(error: unknown) {
  if(error instanceof PlasseringerError) {
    if(error.errorType === BarnehagebarnErrorType.BarnUkjentPerson) {
      return <AlertIkkeVisBarnehageplass/>
    } else if(error.errorType === BarnehagebarnErrorType.GuardianUkjentPerson) {
      return <AlertIkkeVisBarnehageplass/>
    } else if(error.errorType === BarnehagebarnErrorType.InfoIkkeTilgjengelig) {
      return <AlertIkkeVisBarnehageplass/>
    }
  }
  return <AlertError/>
}

const BarnehagebarnList = () => {
  const {keycloak} = useKeycloak()
  let [barn, barnState, error] = useBarnehagePlasseringer(keycloak)
  if(barnState === 'pending') {
    return <LoadingSpinner/>
  }

  return (
    <div className={'pkt-cell pkt-cell--span12 pkt-grid pkt-grid--full'}>
      {barn && barn.length !== 0 && barn.map((b, index) =>
        <BarnehageBarn className="pkt-cell pkt-cell--span12 pkt-cell--span6-tablet-big-up mt-size-16--phablet-up"
                       key={b.navn}
                       child={b}
                       index={index}
        />)}
      {!error && barn && barn.length === 0 &&
        <div className={'pkt-cell pkt-cell--span12 mt-size-24'}>
          <AlertNoPlacement/>
        </div>
      }
      {!!error && (
        <div className={'pkt-cell pkt-cell--span12 mt-size-24'}>
          {getErrorComponent(error)}
        </div>
      )}
    </div>
  )
}


export default BarnehagebarnList
