export type Side = "oversikt" | "pris-søknader" | "avslutt-pris-søknader"

const osloKommunePostfix = '- Oslo kommune'

export const getDefaultDocumentTitle = `Din barnehageoversikt ${osloKommunePostfix}`


export const getSøknadDocumentTitle = (side: Side ) => {
    switch (side) {
        case 'pris-søknader':
            return `Status på søknader om redusert barnehagepris ${osloKommunePostfix}`
        case 'avslutt-pris-søknader':
            return `Stopp automatisk søknad om redusert barnehagepris ${osloKommunePostfix}`
        default:
            return getDefaultDocumentTitle
    }
}
