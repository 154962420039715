import React from 'react'
import FlowerYellowImage from "../../assets/flower_yellow.svg?react"
import FlowerRedImage from "../../assets/flower_red.svg?react"
import FlowerGreenImage from "../../assets/flower_green.svg?react"
import styles from "./styles.module.scss"
import Info from "./Info";
import {classes} from "../../utils/utils";
import {Child} from "../../api/plasseringer";

type BarnehageBarnParams = React.HTMLAttributes<HTMLDivElement> & {
    child: Child,
    index: number
}

const BarnehageBarn = function ({child, className, index}: BarnehageBarnParams) {
    const placement = child.barnehageplasser[0]
    const placementDate = new Date(placement.startDato).toLocaleString(undefined, {dateStyle: "short"})
    const classNames = [className, styles.container].join(" ")
    const position = index % 3

    return (
        <div key={child.navn} className={classNames}>
            <div className={styles.imageContainer}>
                {position == 0 && <FlowerYellowImage/>}
                {position == 1 && <FlowerRedImage/>}
                {position == 2 && <FlowerGreenImage/>}
            </div>
            <div className={styles.textContainer}>
                <p className={classes("pkt-txt-18-medium", styles.title)}>{child.navn}</p>
                <div style={{display: "flex", flexWrap: "wrap"}}>
                    <Info title={"Barnehagestart"} iconName={"calendar"} text={placementDate}></Info>
                    <Info title={"Bydel"} iconName={"map"} text={placement.bydelNavn}></Info>
                    <Info title={"Barnehage"} iconName={"district"} text={placement.barnehageNavn}></Info>
                </div>
            </div>
        </div>
    )
}

export default BarnehageBarn