import React from "react";
import {PktAlert} from "@oslokommune/punkt-react";
import styles from "./alerts.module.scss";
import {classes} from "../../utils/utils";
import {Link} from "react-router-dom";

export const AlertNoPlacement = () => {
    return (
        <div className={classes(styles.simpleEmptyStateMessage)}>
            Vi finner ingen barnehageplasser registrert på deg i Oslo kommune. Hvis du akkurat har takket ja til en
            barnehageplass, kan det ta litt tid før den dukker opp her.
        </div>
    )
};

export const AlertIkkeVisBarnehageplass = () => {
    return (
        <PktAlert
            skin={"info"}
            title={"Informasjon om barnehageplassene dine er ikke tilgjengelig her"}
        >
            <p>
                <Link className="pkt-link"
                      to="https://www.oslo.kommune.no/barnehage/kontakt-barnehageadministrasjonen/">
                    Ta kontakt med bydelen din hvis du har spørsmål.
                </Link>
            </p>
        </PktAlert>
    )
};

export const AlertError = () => (
    <PktAlert className={styles.alert} skin={"error"}>
        Det er en feil i systemet vårt. Vi jobber med å fikse den. Vennligst prøv på
        nytt litt senere.
    </PktAlert>
);

