import {PktBackLink} from "@oslokommune/punkt-react";
import {config} from "../environment";
import React from "react";

export function BackLinkTilOversikt ()  {
    return (
        <PktBackLink
            className={"pl-size-48--laptop-up pl-size-16 pt-size-8"}
            href={config.linkBarnehageBaseUrl}
            text='Din oversikt'
        />
    );
};

