import {config} from "../environment";
import Keycloak from "keycloak-js";
import {useAsync} from "../utils/utils";

export type SakStatus = 'TILBUD' | 'GODTATT' | 'IKKE_BESVART' | 'AVVIST' | 'PLASSERT' | 'UKJENT'

export type SakInfo = {
    id: number
    barnNavn: string
    barnIdent: string
    frist: string | null
    dato: string
    type: 'SØKNAD' | 'TILBUD'
    status: SakStatus
}

type SakInfoResponse = {
    saker: Array<SakInfo>
}

export async function getAktiveSaker(keycloak: Keycloak): Promise<Array<SakInfo>> {
    await keycloak.updateToken(30)
    return fetch(`${config.apiUrl}/api/barnehageplass/aktive-saker`, {
        headers: {Authorization: `Bearer ${keycloak.token}`},
    })
        .then(res => res.json() as Promise<SakInfoResponse>)
        .then(json => json.saker)
}

export const useGetAktiveSaker = useAsync(getAktiveSaker)
