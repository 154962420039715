import {PktAlert} from '@oslokommune/punkt-react'
import LoadingSpinner from "../LoadingSpinner"
import React, {ReactNode} from "react";
import {SakInfo, useGetAktiveSaker} from "../../api/saker";
import {useKeycloak} from "../../keycloak";
import {config} from "../../environment";

export function DineTilbudAlert() {
    const {keycloak} = useKeycloak()
    const [aktiveSaker, aktiveSakerResponseStatus] = useGetAktiveSaker(keycloak)
    const tilbudURL = `${config.linkBarnehageBaseUrl}/barnehageplass/tilbud`

    const alertOmTilbudStatus = (sak: SakInfo): React.ReactNode => {
        switch (sak.status) {
            case 'GODTATT':
                return (
                    <PktAlert
                        skin="success"
                        title={`Du har takket ja til tilbudet om plass for ${sak.barnNavn}`}
                        key={sak.id}
                        compact
                    >
                        Du får snart et brev med mer informasjon om oppstart i barnehagen.
                    </PktAlert>
                )
            case 'TILBUD':
                return (
                    <PktAlert skin="info" title={'Du har fått tilbud om barnehageplass'}
                              key={sak.id} compact>
                        Du har fått et tilbud om barnehageplass for {sak.barnNavn}. Svarfrist er{' '}
                        {sak.frist ? new Date(sak.frist).toLocaleDateString('no', {dateStyle: 'long'}) : 'ikke satt'}.{' '}
                        <a href={`${tilbudURL}/${sak.id}`}
                           className={'pkt-link pkt-txt-16-medium'}>
                            Svar på tilbudet her.
                        </a>
                    </PktAlert>
                )
            default:
                return ''
        }
    }

    function AlertContainer({children}: { children: ReactNode }) {
        return (
            <div className={'mt-size-32 mb-size-8'}>
                {children}
            </div>)
    }

    const tilbudSaker = aktiveSaker?.filter((sak) => sak.type === 'TILBUD' && ["TILBUD", "GODTATT"].includes(sak.status) )
    if (aktiveSakerResponseStatus === 'pending') {
        return (
            <AlertContainer>
                <LoadingSpinner/>
            </AlertContainer>
        )
    }

    if (aktiveSakerResponseStatus !== "ready") {
        return ""
    }

    if (!tilbudSaker || tilbudSaker.length === 0) {
        return ""
    }

    return tilbudSaker.map((tilbudSak) =>
        <AlertContainer key={tilbudSak.id}>
            {alertOmTilbudStatus(tilbudSak)}
        </AlertContainer>
    )
}
