import React, {useEffect} from "react"
import "../main.scss"
import LoadingSpinner from "../components/LoadingSpinner"
import PrissoknadList from "../components/PrissoknadList"
import {useUsername} from "../utils/utils"
import {useKeycloak} from "../keycloak"
import {AlertResoker} from "../components/Alert/AlertResoker";
import {getSøknadDocumentTitle} from "../utils/documentTitle";

function Barnehagepris() {
    const {initialized} = useKeycloak()
    const username = useUsername()
    useEffect(() => {
        document.title = getSøknadDocumentTitle('pris-søknader')
    })

    return (
        <div className="pkt-cell pkt-cell--span12">
            {!initialized && (
                <div className="loading-spinner">
                    <LoadingSpinner/>
                </div>
            )}
            <div className="px-size-8 px-size-16--phablet-up">
                <div className="mx-size-8 mx-size-8--tablet-up my-size-24 my-size-64--tablet-up">
                    <h2 className="bhg-hello">
                        Hei, {username}
                    </h2>
                    <p className="ingress">
                        Her finner du status på søknaden din om redusert barnehagepris.
                    </p>
                </div>
                <AlertResoker className={'mb-size-32'}/>
                <PrissoknadList/>
            </div>
        </div>
    )
}

export default Barnehagepris
