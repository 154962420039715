import React, {useEffect, useRef} from 'react';
import styles from './styles.module.scss'
import {classes} from "../../utils/utils";
import { useFocusTrap } from './useFocusTrap';

type ModalProps = React.HTMLAttributes<HTMLDialogElement> & {
    show: Boolean
    onBackgroundClick?: (() => void)
    onClose?: (() => void)
};

function applyStyles(target: HTMLElement, styles: CSSStyleDeclaration) {
    for (const prop in styles) {
        target.style[prop] = styles[prop]
    }
}

function revertStyles(target: HTMLElement, styles: CSSStyleDeclaration, originalStyles: CSSStyleDeclaration) {
    for (const prop in styles) {
        target.style[prop] = originalStyles[prop] ?? ""
    }
}

const Modal = ({children, show = false, onBackgroundClick, onClose, ...restProps}: ModalProps) => {
    const scrollTop = useRef<number | undefined>(undefined)
    const originalStyles = useRef({} as CSSStyleDeclaration)
    const dialog = useRef<HTMLDialogElement>(null)
    const prematureClose = useRef(false)
    let scrollContainer = document.scrollingElement || document.documentElement
    useFocusTrap(show, dialog);


    useEffect(() => {
        const overrideStyles = {
            overflow: "hidden",
            position: "fixed",
            height: "100%",
            width: "100%",
            top: `${-scrollContainer.scrollTop}px`,
        } as CSSStyleDeclaration

        if (show) {
            if (dialog.current && !dialog.current.open) {
                dialog.current?.showModal()
            }
            scrollTop.current = scrollContainer.scrollTop
            originalStyles.current = {...document.body.style}
            applyStyles(document.body, overrideStyles)
        } else {
            if (dialog.current?.open) {
                dialog.current?.close()
            }
            revertStyles(document.body, overrideStyles, originalStyles.current)
            if(scrollTop.current){
                scrollContainer.scrollTop = scrollTop.current
            }
        }
    }, [show])

    return (
        <>
            {show &&
                <dialog {...restProps}
                        ref={dialog}
                        onClose={() => {
                            if(prematureClose.current) {
                                prematureClose.current = false
                                dialog.current?.showModal()
                            } else {
                                onClose?.()
                            }
                        }
                        }
                        onCancel={e => {
                            if(e.target != dialog.current) {
                                // This is a workaround for a bug(?) in chromium (https://bugs.chromium.org/p/chromium/issues/detail?id=1449848)
                                // where a child <input type="file"> cancel event bubbles up to the <dialog> and causes it to close.
                                // in other browsers you can intercept the event and either stopPropagation or preventDefault, but for some reason
                                // that doesn't work in chrome.
                                prematureClose.current = true
                            } else {
                                onClose?.()
                            }
                        }}
                        className={classes(restProps.className, styles.modalDialog)}>
                    {children}
                </dialog>}
        </>
    )
}

export default Modal