import React, {HTMLAttributes, useMemo} from "react";
import {useKeycloak} from "../../keycloak";
import {usePrissoknader} from "../../api/prissoknader";
import {ResoknadMedlemskap, useResoknadStatus} from "../../api/resoknadstatus";
import LoadingSpinner from "../LoadingSpinner";
import {PktMessagebox} from "@oslokommune/punkt-react";
import {Link} from "react-router-dom";

export const AlertResoker = (attrs: HTMLAttributes<HTMLDivElement>) => {
    const {keycloak} = useKeycloak()
    const [prissoknader, prissoknaderStatus] = usePrissoknader(keycloak)
    const [medlemskap, medlemskapStatus] = useResoknadStatus(keycloak)

    const harAktivResoknad = useMemo(() => {
        return prissoknader?.some(prissoknad => {
            return prissoknad.søknadstype === 'RESØKNAD' && (
                prissoknad.søknadsstatus === 'OVERSENDT_SAKSBEHANDLER' || prissoknad.søknadsstatus === 'VENTER_PÅ_SKATTEOPPGJØR'
            )
        })
    }, [prissoknader])

    if (prissoknaderStatus === 'pending' || medlemskapStatus === 'pending') {
        return <LoadingSpinner/>
    }

    if (medlemskap === "INGEN") {
        return ""
    }


    return (
        <div {...attrs}>
            {medlemskap === ResoknadMedlemskap.AKTIV && !harAktivResoknad && (
                <AlertResoknadActiveNoResoknad/>
            )}
            {medlemskap === ResoknadMedlemskap.AKTIV && harAktivResoknad && (
                <AlertResoknadActiveHasResoknad/>
            )}
            {medlemskap === ResoknadMedlemskap.TRUKKET_SEG && (
                <AlertResoknadNotActive/>
            )}
        </div>
    )
};

const AlertResoknadActiveNoResoknad = () => {
    return (
        <PktMessagebox
            compact
            title='Vi søker om redusert barnehagepris for deg'
            skin="green"
        >
            <p>
                Du, eller noen i husholdningen din, har tidligere søkt om redusert barnehagepris. Oslo kommune vil
                derfor søke på deres vegne hvert år fram til barnet begynner på skolen.
            </p>
            <p>
                Dere trenger ikke gjøre noe selv. Vi sender en bekreftelse når søknad for neste barnehageår er sendt
                til behandling i april/mai.
            </p>
            <p>
                Har dere ikke lenger behov for redusert barnehagepris?{" "}
                <Link className="pkt-link" to="/barnehagepris/avsluttsoknad">
                    Stopp søknadene her.
                </Link>
            </p>
        </PktMessagebox>
    )
}

const AlertResoknadActiveHasResoknad = () => {
    return (
        <PktMessagebox
            title='Vi søker om redusert barnehagepris for deg'
            compact
            skin="green"
        >
            <p>
                Du, eller noen i husholdningen din, har tidligere søkt om redusert barnehagepris. Oslo kommune har
                derfor laget en søknad for dere for neste barnehageår. Denne er nå til behandling.
            </p>
            <p>
                Har dere ikke lenger behov for redusert barnehagepris?{" "}
                <Link className="pkt-link" to="/barnehagepris/avsluttsoknad">
                    Stopp behandling av søknaden her.
                </Link>
            </p>
        </PktMessagebox>
    )
}

const AlertResoknadNotActive = () => {
    return (
        <PktMessagebox
            skin={"beige"}
            compact
            title='Du har ingen aktive søknader'
        >
            <p>
                Du har tidligere søkt om redusert barnehagepris, men har stoppet alle framtidige søknader. Det betyr
                at dere må søke på nytt selv hvis dere får behov for lavere barnehagepris igjen.
            </p>
            <p>
                Har du spørsmål eller mener det har skjedd en feil, ring kontaktsenteret på telefon 21 80 21 80.
            </p>
        </PktMessagebox>
    )
}

