import Keycloak from "keycloak-js";
import {useAsync} from "../utils/utils";
import {config} from "../environment";

export type SoknadDokumentasjon = {
  filnavn: string,
  opplastningstidspunkt: string
}

export type Prissoknad = {
  søknadstype: string, //"RESØKNAD" | "FØRSTEGANG",
  brukerreferanse: string,
  innsendtdato: string,
  søknadsstatus: string, //"OVERSENDT_SAKSBEHANDLER" | "VENTER_PÅ_SKATTEOPPGJØR" | "FERDIGBEHANDLET",
  barnehagenavn: string | null,
  bydel: string,
  bydelTelefonnummer: string,
  hovedsøkerFulltNavn: string,
  brukeropplastetDokumentasjon: Array<SoknadDokumentasjon>
}

type PrissoknadResponse = {
  søknader: Array<Prissoknad>
}

async function getPrissoknader(keycloak: Keycloak): Promise<Array<Prissoknad>> {
  await keycloak.updateToken(30)
  return fetch(`${config.apiUrl}/api/soknader`, {
    headers: {Authorization: `Bearer ${keycloak.token}`},
  })
  .then(res => res.json() as Promise<PrissoknadResponse>)
  .then(json =>
    json.søknader.sort((a, b) =>
      a.innsendtdato < b.innsendtdato ? 1 : -1))
}


export const usePrissoknader = useAsync(getPrissoknader)