import React from 'react';
import {PktHeader} from "@oslokommune/punkt-react";
import {useUsername} from "../utils/utils";
import { useKeycloak } from '../keycloak';

//todo: replace useUsername with a context?
export default function Header() {
    const {keycloak} = useKeycloak()
    return (
        <PktHeader
            serviceName={"Barnehage"}
            fixed={false}
            scrollToHide={false}
            canChangeRepresentation={false}
            showLogOutButton={true}
            logOut={() => keycloak.logout()}
        />
    )
}