import Keycloak from "keycloak-js";
import {config} from "../environment";

export async function trekkHustandOgAlleAktivePrissoknader(keycloak: Keycloak): Promise<void> {
  await keycloak.updateToken(30);

  return fetch(`${config.apiUrl}/api/avsluttsoknad`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${keycloak.token}`
    },
  })
  .then((res) => {
    if (!res.ok) {
      throw new Error(`Server error: ${res.status}`);
    }
  });
}
