import React from 'react'
import {isRouteErrorResponse, useRouteError} from 'react-router-dom'
import {submitError} from '../../errorReport'
import {ErrorLayout} from './components/ErrorLayout'
import {BackLinkTilOversikt} from "../../components/BackLinkTilOversikt";

export default function ErrorHandler() {
    const error = useRouteError()

    if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
            submitError('Bruker har kommet til en side som ikke finnes', error)
            return (
                <>
                    <BackLinkTilOversikt/>
                    <ErrorLayout>
                        <h1 className={'pkt-txt-22 mb-size-20'}>404 - Beklager, vi finner ikke siden du leter etter</h1>
                        <p>Det kan være at siden er slettet, eller at det er en feil i lenken du fulgte for å komme
                            hit.</p>
                        <p><a href={"https://www.oslo.kommune.no/barnehage/"}>Gå til startsiden for barnehage</a></p>
                    </ErrorLayout>
                </>)
        }
    }

    submitError('Errorhandleren default beskjed er blitt vist til brukeren', error)
    return (
        <>
            <BackLinkTilOversikt/>
            <ErrorLayout>
                <h1 className={'pkt-txt-22'}>Beklager, det skjedde en feil. Vi jobber med å fikse den.</h1>
            </ErrorLayout>
        </>
    )
}