import {useKeycloak} from "../../keycloak";
import {useUsername} from "../../utils/utils";
import LoadingSpinner from "../../components/LoadingSpinner";
import React, {ReactNode, useEffect} from "react";
import BarnehagebarnList from "../../components/BarnehagebarnList";
import {PktLinkCard} from "@oslokommune/punkt-react";
import {config} from "../../environment";
import {DineTilbudAlert} from "../../components/DineTilbudAlert/DineTilbudAlert";
import {AlertResoker} from "../../components/Alert/AlertResoker";
import {getSøknadDocumentTitle} from "../../utils/documentTitle";

type OversiktSectionProps = {
    title: string
    children: ReactNode
}

function OversiktSection({title, children}: OversiktSectionProps) {
    return (
        <section className={'pkt-cell pkt-cell--span12'}>
            <h1 className={'pkt-txt-28 pkt-txt-30--tablet-up mb-size-8'}>
                {title}
            </h1>
            {children}
        </section>
    )
}

type OversiktLinkProps = {
    title: string
    href: string
    children: ReactNode
    external?: boolean
}

function OversiktLink({title, href, children, external}: OversiktLinkProps) {
    return (
        <PktLinkCard className={'pkt-cell pkt-cell--span12 pkt-cell--span4-tablet-big-up'}
                     title={title}
                     external={external}
                     openInNewTab={external}
                     iconName={'chevron-right'}
                     href={href}>
            {children}
        </PktLinkCard>
    )
}

function OversiktLinkContainer({children}: { children: ReactNode }) {
    return (
        <div className={"pkt-grid pkt-grid--rowgap-size-0 pkt-grid--rowgap-size-32-tablet-big-up"}>
            {children}
        </div>
    )

}

export function Oversikt() {
    const {initialized} = useKeycloak()
    const username = useUsername()
    useEffect(() => {
        document.title = getSøknadDocumentTitle('oversikt')
    })

    const plassSøknadUrl = `${config.linkBarnehageBaseUrl}/barnehageplass`
    const plassDineSøknaderUrl = `${config.linkBarnehageBaseUrl}/barnehageplass/dine-søknader`
    const istPlasseringerUrl = config.linkIstPlasseringer
    const skjema440Url = config.link440Skjema
    const prisKuenUrl = `${config.linkBarnehageBaseUrl}/barnehagepris`
    const prisAvsluttUrl = `${config.linkBarnehageBaseUrl}/barnehagepris/avsluttsoknad`

    return (
        <>
            <div className="pkt-cell pkt-cell--span12">
                {!initialized && (
                    <div className="loading-spinner">
                        <LoadingSpinner/>
                    </div>
                )}
                <div className="">
                    <div
                        className="my-size-24 mt-size-64--tablet-up mb-size-40--tablet-up">
                        <h1 className="pkt-txt-36 pkt-txt-40--tablet-up">
                            Hei, {username}
                        </h1>
                    </div>
                </div>
            </div>

            <OversiktSection title={'Søknad om barnehageplass'}>
                <DineTilbudAlert/>
                <OversiktLinkContainer>
                    <OversiktLink title={'Søk om barnehageplass'} href={plassSøknadUrl}>
                        Send inn ny en søknad, søk om å bytte barnehage.
                    </OversiktLink>
                    <OversiktLink title={'Dine søknader'} href={plassDineSøknaderUrl}>
                        Se, endre eller slette søknad, svar på tilbud om plass.
                    </OversiktLink>
                    <OversiktLink title={'Oppsigelse og permisjon'} external={true} href={istPlasseringerUrl}>
                        Si opp plassen eller søk om permisjon.
                    </OversiktLink>
                </OversiktLinkContainer>
            </OversiktSection>

            <OversiktSection title={'Søknad om redusert barnehagepris'}>
                <AlertResoker className={"mt-size-32 mb-size-8"}/>
                <OversiktLinkContainer>
                    <OversiktLink title={'Søk om redusert barnehagepris'} href={skjema440Url}>
                        Send inn en ny søknad.
                    </OversiktLink>
                    <OversiktLink title={'Se status på søknad'} href={prisKuenUrl}>
                        Følg status, ettersend vedlegg.
                    </OversiktLink>
                    <OversiktLink title={'Stopp automatisk søknad'} href={prisAvsluttUrl}>
                        Gi beskjed dersom du ikke har behov for redusert pris lenger.
                    </OversiktLink>
                </OversiktLinkContainer>
            </OversiktSection>

            <OversiktSection title={'Dine barnehageplasser'}>
                <BarnehagebarnList/>
            </OversiktSection>
        </>
    )
}